import React, { ReactNode } from "react";
import { BodyBox, CardTypography } from "./table.styled";

interface Body {
  text: string;
  width: string;
  fontSize?: string;
  isAction?: boolean;
  actionComponent?: ReactNode;
}

interface BodyCardProps {
  bodyContent: Body[];
}

const BodyCard: React.FC<BodyCardProps> = ({ bodyContent }) => {
  return (
    <BodyBox>
      {bodyContent.map((body, index) => (
        <CardTypography
          key={`${index}-${body.text} body`}
          width={body.width}
          fontSize={body.fontSize || "15px"}
          color="black"
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
          variant="body1"
        >
          {body.isAction && body.actionComponent
            ? body.actionComponent
            : body.text}
        </CardTypography>
      ))}
    </BodyBox>
  );
};

export default BodyCard;
