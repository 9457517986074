/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import FileOpen from "@mui/icons-material/FolderOpen";
import { UnderlineRowDiv } from "../../../../components/misc/misc.styled";

import { AppCSS, Spacer, TxtInput, TapButton } from "../../../../components";
import { SFileUpload } from "../../../../components/input/input.styled";
import { RowDiv, ColDiv } from "../../../../components/misc/misc.styled";
import { FC, useRef, useState } from "react";
import axios from "axios";
import { DeleteDoc, GenerateCertificate } from "../../../../redux/api";
import { toast } from "react-toastify";
import { Docs, Project } from "../../../../redux/types";
import { ProjectAddOns } from "../../../../components/ProjectAddOns";
import { EstimateAddOns } from "../../../../redux/types/Estimate";
import { AlertWithContent } from "../../../../components/dialogue/Dialogue";
import { TinyLbl } from "../../../../components/labels/labels.styled";
import { BASE_URL } from "../../../../redux/api/appConst";
import { GenerateInvoice } from "../../../../redux/api/estimate";
import { Grid, IconButton } from "@mui/material";
import FileIcon from "../../../../images/file_icon.png";
import DeleteIcon from "@mui/icons-material/Delete";

interface CertificateProps {
  onFetchProject: Function;
  project?: Project;
  docs?: Docs[];
  estimateAddOns: EstimateAddOns[];
}

export const CertificateOfCompletion: FC<CertificateProps> = ({
  project,
  docs,
  estimateAddOns,
  onFetchProject,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [updateFile, setUpdateFile] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const [metaData, setMetaData] = useState("");

  const [isUpload, setIsUpload] = useState(false);

  const uploadImage = async () => {
    try {
      if (metaData === "") {
        toast("Please provide image description", {
          type: "error",
        });
        return;
      }
      if (!fileRef.current?.files) {
        return;
      }
      const file = fileRef.current.files[0];

      const form = new FormData();
      form.append("file", file);
      form.append("desc", metaData);
      const token = localStorage.getItem("token") as string;

      const result = await axios.post(
        `${BASE_URL}/upload/${project.id}/img`,
        form,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result.status === 200) {
        toast("Successfully uploaded image", {
          type: "success",
        });
        setIsUpload(false);
        onFetchProject();
      } else {
        toast("Failed to upload image", {
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      toast("Failed to upload image", {
        type: "error",
      });
    }
  };

  const onGetSignedUrl = async () => {
    if (!fileRef.current?.files) {
      return;
    }
  };

  const onGenerateCompletion = async () => {
    if (!project) {
      alert("can't generate certificate!");
    }
    try {
      const status = await GenerateCertificate(project.id);
      if (status === 201) {
        toast("Certificate generated successfully!", {
          type: "success",
        });
        onFetchProject();
      } else {
        toast("Failed to generate certificate", {
          type: "error",
        });
      }
    } catch (error) {
      toast("Failed to generate certificate", {
        type: "error",
      });
    }
  };

  function formatString(str) {
    return str
      .split("_") // Split string on underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
      .join(" "); // Join the words with spaces
  }

  const onTapGenerateInvoice = async () => {
    if (!project) {
      alert("can't generate invoice!");
    }
    try {
      const status = await GenerateInvoice(project.id);
      if (status === 200) {
        onFetchProject();
        toast("Certificate generated successfully!", {
          type: "success",
        });
      } else {
        toast("Failed to generate certificate", {
          type: "error",
        });
      }
    } catch (error) {
      toast("Failed to generate certificate", {
        type: "error",
      });
    }
  };

  const uploadFileContent = () => {
    return (
      <div>
        <RowDiv
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <ColDiv>
            <TinyLbl>Image Description</TinyLbl>
            <TxtInput
              value={metaData}
              placeholder="Image description"
              onChange={setMetaData}
            />
          </ColDiv>
          <ColDiv
            style={{
              width: 100,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SFileUpload
              style={{
                border: updateFile.length > 0 ? `3px solid #A9CBB7` : "none",
              }}
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.click();
                }
              }}
            >
              <input
                type="file"
                id="file"
                ref={fileRef}
                onChange={onGetSignedUrl}
                style={{
                  display: "none",
                }}
              />
              <FileOpen
                titleAccess="Select File"
                color="warning"
                style={{ width: 32, height: 32, color: AppCSS.GLAS_GREEN }}
              />
            </SFileUpload>
          </ColDiv>
        </RowDiv>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <TapButton
            width={100}
            height={40}
            bgColor={AppCSS.DANGER}
            title="Cancel"
            onTap={() => setIsUpload(false)}
          />
          <Spacer size={4} direction="row" />

          <TapButton
            width={100}
            height={40}
            bgColor={AppCSS.GLAS_BLUE}
            title="Submit"
            onTap={() => uploadImage()}
          />
        </RowDiv>
      </div>
    );
  };

  const DeleteDocs = async (docId: number) => {
    try {
      const status = await DeleteDoc(docId);
      if (status === 200) {
        onFetchProject();
      }
    } catch (error) {}
  };

  const certificateDetails = () => {
    return (
      <>
        <AlertWithContent
          title="Upload Image"
          open={isUpload}
          Content={uploadFileContent()}
        />
        <UnderlineRowDiv style={{ marginLeft: 20 }}>
          <h2>Invoice</h2>
        </UnderlineRowDiv>
        <Spacer size={1} direction="col" />
        <RowDiv
          style={{
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <ColDiv>
            <Grid
              style={{
                width: "100%",
              }}
              container
              spacing={3}
              margin={1}
            >
              {Array.isArray(docs) &&
                docs
                  .filter((item) => item.file_type.toLowerCase() === "invoice")
                  .map((item) => (
                    <Grid
                      style={{
                        flexDirection: "column",
                        margin: "5px",
                        padding: "5px",
                        paddingTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "120px",
                        maxWidth: "240px",
                      }}
                      item
                      xs
                    >
                      <a
                        href={`${item.file_url}`}
                        target="_blank"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: 13,
                          color: AppCSS.RED,
                        }}
                      >
                        <img
                          src={FileIcon}
                          alt={item.file_type}
                          loading="lazy"
                          style={{
                            display: "flex",
                            width: 60,
                            height: 60,
                          }}
                        />
                        <p>{item.file_type}</p>
                      </a>
                    </Grid>
                  ))}
            </Grid>
          </ColDiv>

          <ColDiv>
            <ProjectAddOns
              title="AddOns"
              AddOnType="invoice"
              project={project}
              estimateAddOns={estimateAddOns}
            />
            <ProjectAddOns
              title="Credits"
              AddOnType="credits"
              project={project}
              estimateAddOns={estimateAddOns}
            />
          </ColDiv>
        </RowDiv>
        <RowDiv>
          <TapButton
            height={52}
            width={220}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            onTap={() => onTapGenerateInvoice()}
            title="Generate Invoice"
          />
        </RowDiv>
        <Spacer size={1} direction="col" />

        <UnderlineRowDiv style={{ marginLeft: 20 }}>
          <h2>Certificate of Completion</h2>
        </UnderlineRowDiv>
        <Spacer size={1} direction="col" />
        <Grid
          style={{
            width: "100%",
          }}
          container
          spacing={3}
          margin={1}
        >
          {Array.isArray(docs) &&
            docs
              .filter((item) => item.file_type.toLowerCase() === "coc")
              .map((item) => (
                <Grid
                  style={{
                    flexDirection: "column",
                    margin: "5px",
                    padding: "5px",
                    paddingTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "120px",
                    maxWidth: "240px",
                  }}
                  item
                  xs
                >
                  <a
                    href={`${item.file_url}`}
                    target="_blank"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: 13,
                      color: AppCSS.RED,
                    }}
                  >
                    <img
                      src={FileIcon}
                      alt={item.file_type}
                      loading="lazy"
                      style={{
                        display: "flex",
                        width: 60,
                        height: 60,
                      }}
                    />
                    <p>{item.file_type}</p>
                  </a>
                </Grid>
              ))}
        </Grid>
        <RowDiv
          style={{
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <ColDiv
            style={{
              width: "70%",
            }}
          >
            <table style={{ width: "100%" }}>
              <tr
                style={{
                  width: "50%",
                  height: "40px",
                  background: AppCSS.GRAY_MILD,
                }}
              >
                <th style={{ color: "black" }}>Files</th>
                <th style={{ color: "black" }}>Link</th>
                <th style={{ color: "black" }}>Metadata</th>
                <th style={{ color: "black" }}>Action</th>
              </tr>
              {Array.isArray(docs) &&
                docs
                  .filter((item) => item.file_type.toLowerCase() === "image")
                  .map((item, index) => (
                    <tr key={index} style={{ width: "100%" }}>
                      <td
                        style={{
                          paddingLeft: 20,
                          textAlign: "center",
                        }}
                      >
                        <p>{formatString(item.file_type)}</p>
                      </td>
                      <td
                        style={{
                          paddingLeft: 5,
                          textAlign: "center",
                        }}
                      >
                        {item.file_type === "image" ? (
                          <a
                            href={`${item.file_url}`}
                            target="_blank"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              color: AppCSS.GLAS_BLUE,
                            }}
                          >
                            <img src={item.file_url} width={80} height={40} />
                          </a>
                        ) : (
                          <a
                            href={`${item.file_url}`}
                            target="_blank"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 15,
                              color: AppCSS.GLAS_BLUE,
                            }}
                          >
                            <p>View File</p>
                          </a>
                        )}
                      </td>
                      <td
                        style={{
                          paddingLeft: 5,
                          textAlign: "center",
                        }}
                      >
                        {item.meta_data}
                      </td>
                      <td
                        style={{
                          paddingLeft: 5,
                          textAlign: "center",
                        }}
                      >
                        {
                          <IconButton onClick={() => DeleteDocs(item.id)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        }
                      </td>
                    </tr>
                  ))}
            </table>
          </ColDiv>

          <ColDiv
            style={{
              width: "30%",
              marginLeft: 15,
            }}
          >
            <TapButton
              width={100}
              height={40}
              bgColor={AppCSS.GLAS_BLUE}
              title="Upload"
              onTap={() => setIsUpload(true)}
            />
          </ColDiv>
        </RowDiv>
        <Spacer size={3} direction="col" />
        <RowDiv>
          <TapButton
            height={52}
            width={220}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            color={AppCSS.GLAS_BLUE}
            onTap={() => onGenerateCompletion()}
            title="Generate Certificate"
          />
        </RowDiv>
      </>
    );
  };

  return certificateDetails();
};
