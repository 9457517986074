/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { ProjectTable } from "./ProjectTable";
import { GetProjects } from "../../../redux/api";
import { setProjects } from "../../../redux/reducers";
import { Project } from "../../../redux/types";
import { Container } from "../../../globalstyled";
interface DashboardProps {}

export const Projects: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();
  const userReducer = useAppSelector((state) => state.userReducer);
  const { projects } = userReducer;

  useEffect(() => {
    onFetchLeads();
  }, []);

  const onFetchLeads = async () => {
    try {
      const data = await GetProjects();
      const userData = data as {
        message: string;
        data: { count: number; projects: Project[] };
      };
      if (userData.data) {
        const { projects } = userData.data;
        dispatch(setProjects(projects));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <ProjectTable projects={projects} />
      </Container>
    );
  };

  return hostContainer();
};
