/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { TapButton, AppCSS, Spacer } from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { GetProject } from "../../../redux/api";
import { Docs, Progress, Project } from "../../../redux/types";
import {
  setEstimate,
  setEstimateAddOns,
  setProjectDetails,
} from "../../../redux/reducers";

import { CertificateOfCompletion } from "./CertificateOfCompletion";
import { ProgressSlider } from "./ProgressSlider";
import { CustomerDetails } from "./CustomerDetails";
import { InsuranceDetails } from "./InsuranceDetails";
import { MaterialDetails } from "./MaterialDetails";
import { RoofDetails } from "./RoofDetails";
import { InstallationDetails } from "./InstallationDetails";
import { PaymentDetails } from "./PaymentDetails";
import { DocumentDetails } from "./Documents";
import { Material } from "../../../redux/types/Material";
import { EstimateDetails } from "./Estimate";
import { GetEstimate, GetEstimateAddOns } from "../../../redux/api/estimate";
import { Estimate, EstimateAddOns } from "../../../redux/types/Estimate";
import { DeleteProject } from "./Delete Project";
import { Box, Tab, Tabs } from "@mui/material";

interface DashboardProps {}

export const ProjectDescription: React.FC<DashboardProps> = ({}) => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { projectDetails, estimate, estimateAddOns } = useAppSelector(
    (state) => state.userReducer
  );
  const { project, progress, docs, projectMaterials } = projectDetails;
  const [value, setValue] = useState(0);
  const [viewProgress, setViewProgress] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (id) {
      onFetchProject();
      onFetchEstimate();
      onFetchEstimateAddOns();
    }
  }, [id]);

  const onFetchProject = async () => {
    try {
      const data = await GetProject(Number(id));
      const userData = data as {
        message: string;
        project: Project;
        progress: Progress[];
        docs: Docs[];
        materials: Material[];
      };
      if (userData.project) {
        dispatch(
          setProjectDetails({
            project: userData.project,
            progress: userData.progress,
            docs: userData.docs,
            projectMaterials: userData.materials,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchEstimate = async () => {
    try {
      const data = await GetEstimate(Number(id));
      const response = data.data as Estimate;
      if (response) {
        dispatch(setEstimate(response));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFetchEstimateAddOns = async () => {
    try {
      const data = await GetEstimateAddOns(Number(id));
      console.log(data);
      const response = data.data as EstimateAddOns[];
      if (response) {
        dispatch(setEstimateAddOns(response));
      }
    } catch (error) {
      console.log(error);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        background: AppCSS.WHITE,
        justifyContent: "center",
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <ColDiv
        style={{
          width: "90%",
        }}
      >
        <RowDiv>
          <ColDiv>
            <Lbl title="Project Details" size={20} bold={600} />
          </ColDiv>
          <Spacer size={2} direction="row" />

          <TapButton
            title={viewProgress ? "Hide Progress" : "View Progress"}
            onTap={() => {
              setViewProgress(!viewProgress);
            }}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_YELLOW}
            color={AppCSS.GLAS_BLUE}
            width={200}
            height={40}
            radius={10}
          />
          <TapButton
            title="← Back"
            onTap={() => {
              navigate(-1);
            }}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_YELLOW}
            color={AppCSS.GLAS_BLUE}
            width={120}
            height={40}
            radius={10}
          />
        </RowDiv>
        {viewProgress ? <ProgressSlider progress={progress} /> : null}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Customer Details" {...a11yProps(0)} />
            <Tab label="Roof Details" {...a11yProps(1)} />
            <Tab label="Insurance Scope" {...a11yProps(2)} />
            <Tab label="Materials" {...a11yProps(3)} />
            <Tab label="Estimate" {...a11yProps(4)} />
            <Tab label="Installation Details" {...a11yProps(5)} />
            <Tab label="Payment Details" {...a11yProps(6)} />
            <Tab label="Documents" {...a11yProps(7)} />
            <Tab label="COC / Invoice" {...a11yProps(8)} />
            {/* <Tab label="Invoice" {...a11yProps(9)} /> */}
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CustomerDetails
            onFetchProgress={onFetchProject}
            project={project}
            progress={progress}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <RoofDetails project={project} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <InsuranceDetails
            estimate={estimate}
            project={project}
            estimateAddOns={estimateAddOns}
            onFetchEstimate={() => onFetchEstimate()}
            docs={docs}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <MaterialDetails materials={projectMaterials} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <EstimateDetails
            onFetchProject={() => onFetchEstimate()}
            projectId={+id}
            estimate={estimate}
            docs={docs}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <InstallationDetails project={project} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <PaymentDetails
            docs={docs}
            project={project}
            progress={progress}
            onCompleted={() => {
              onFetchProject();
            }}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <DocumentDetails
            onFetchProject={() => onFetchProject()}
            project={project}
            docs={docs}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <CertificateOfCompletion
            project={project}
            docs={docs}
            estimateAddOns={estimateAddOns}
            onFetchProject={() => onFetchProject()}
          />
        </CustomTabPanel>
        {/* <CustomTabPanel value={value} index={9}>
          <Invoice
            estimateAddOns={estimateAddOns}
            project={project}
            docs={docs}
            onFetchEstimate={() => onFetchProject()}
          />
        </CustomTabPanel> */}

        <DeleteProject
          project={project}
          docs={docs}
          onDelete={() => navigate(-1)}
        />
      </ColDiv>
    </div>
  );
};
