/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { CenterBox, ColDiv, RowDiv } from "../../components/misc/misc.styled";
import { Lbl } from "../../components/labels";
import {
  AppCSS,
  Spacer,
  TapButton,
  TxtInput,
  TermsAndConditionCheck,
} from "../../components";

import { useEffect, useState } from "react";
import { LoginContainer } from "./login.styled";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userLogin } from "../../redux/reducers";
import { LoginAPI } from "../../redux/api/admin.api";

interface LoginProps {}

export const Login: React.FC<LoginProps> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [forgot, setForgot] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const profile = useAppSelector((state) => state.userReducer).userProfile;

  // useEffect(() => {
  //   if (!profile.token) {
  //     toast("Login Credentials are wrong!", {
  //       type: "error",
  //     });
  //   } else {
  //     setTimeout(() => {
  //       navigate("/dashboard");
  //     }, 3000);
  //   }
  // }, [profile.token]);

  const onTapSignin = async () => {
    if (email === "" || password === "") {
      toast("Please enter email address and password!", {
        type: "error",
      });
      return;
    }

    try {
      const data = await LoginAPI(email, password);
      const userData = data as { message: string; data: string };
      if (userData.data) {
        localStorage.setItem("token", userData.data);
        dispatch(userLogin({ token: userData.data, _id: "" }));

        navigate("/dashboard");
      } else {
        localStorage.removeItem("token");
      }
    } catch (error) {
      console.log(error);
    }

    // dispatch(onShowLoader());
    // dispatch(LoginAPI(email, password));
  };

  const onTapResetPassword = () => {
    if (email === "") {
      toast("Please enter email address!", {
        type: "error",
      });
      return;
    }
    // dispatch(onShowLoader("Loading..."));
    // dispatch(onResetPassword({ email }));
    // // TODO: check if email is incorporated or not after that show success message
    setTimeout(() => {
      toast("Reset password link sent to your registered password link!", {
        type: "success",
      });
      setEmail("");
      setForgot(false);
    }, 2000);
  };

  const loginView = () => {
    return (
      <CenterBox
        style={{
          maxWidth: "420px",
        }}
      >
        <RowDiv>
          <Lbl title="Sign in" size={25} bold={500} />
        </RowDiv>

        <ColDiv>
          <TxtInput placeholder="Email address" onChange={setEmail} />
          <Spacer size={2} direction="col" />
          <TxtInput
            type="password"
            placeholder="Password"
            onChange={setPassword}
            onPressEnter={() => {
              onTapSignin();
            }}
          />
        </ColDiv>
        <Spacer size={2} direction="col" />
        <RowDiv
          style={{
            justifyContent: "space-between",
          }}
        >
          <TapButton
            onTap={() => setForgot(true)}
            title="Forgot Password"
            bgColor={AppCSS.WHITE}
            color={AppCSS.GLAS_GREEN}
            height={40}
          />
          <Spacer size={2} direction="row" />
          <TapButton
            onTap={onTapSignin}
            title="Sign In"
            bgColor={AppCSS.GLAS_BLUE}
            height={40}
          />
        </RowDiv>
        <Spacer size={1} direction="col" />

        <Spacer size={2} direction="col" />
      </CenterBox>
    );
  };

  const forgotView = () => {
    return (
      <CenterBox
        style={{
          maxWidth: "420px",
        }}
      >
        <RowDiv>
          <Lbl title="Forgot Password" size={18} bold={600} />
        </RowDiv>
        <Spacer size={4} direction="col" />
        <ColDiv>
          <TxtInput placeholder="Email address" onChange={setEmail} />
        </ColDiv>
        <Spacer size={2} direction="col" />

        <RowDiv
          style={{
            justifyContent: "space-between",
          }}
        >
          <TapButton
            onTap={() => setForgot(false)}
            title="Cancel"
            color={AppCSS.GLAS_BLUE}
            bgColor={AppCSS.WHITE}
            borderColor={AppCSS.GLAS_BLUE}
            height={40}
          />
          <Spacer size={2} direction="row" />

          <TapButton
            onTap={onTapResetPassword}
            title="Reset Password"
            bgColor={AppCSS.GLAS_BLUE}
            height={40}
          />
        </RowDiv>
      </CenterBox>
    );
  };

  return <LoginContainer>{forgot ? forgotView() : loginView()}</LoginContainer>;
};
