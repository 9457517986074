import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminUserModel,
  LeadModel,
  Project,
  ProjectDetails,
  UserModel,
} from "../types";
import { InsuranceCompany } from "../types/InsuranceCompany";
import { Material } from "../types/Material";
import { Estimate, EstimateAddOns } from "../types/Estimate";

export interface UserState {
  userProfile: UserModel;
  adminUsers: AdminUserModel[];
  leads: LeadModel[];
  projects: Project[];
  insuranceCompanies: InsuranceCompany[];
  projectDetails: ProjectDetails;
  materials: Material[];
  estimate: Estimate;
  estimateAddOns: EstimateAddOns[];
}

const initialState: UserState = {
  userProfile: {} as UserModel,
  adminUsers: {} as AdminUserModel[],
  leads: {} as LeadModel[],
  projects: {} as Project[],
  insuranceCompanies: {} as InsuranceCompany[],
  projectDetails: {} as ProjectDetails,
  materials: {} as Material[],
  estimate: {} as Estimate,
  estimateAddOns: {} as EstimateAddOns[],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin(state, action: PayloadAction<UserModel>) {
      const profileData = action.payload;
      state.userProfile = profileData;
    },
    userLogout(state) {
      state.userProfile = {} as UserModel;
    },
    setLeads(state, action: PayloadAction<LeadModel[]>) {
      console.log("ACTION:", action);
      state.leads = action.payload;
    },
    setProjects(state, action: PayloadAction<Project[]>) {
      console.log("ACTION:", action);
      state.projects = action.payload;
    },
    setInsuranceCompanies(state, action: PayloadAction<InsuranceCompany[]>) {
      state.insuranceCompanies = action.payload;
    },
    setProjectDetails(state, action: PayloadAction<ProjectDetails>) {
      state.projectDetails = action.payload;
    },
    setMaterials(state, action: PayloadAction<Material[]>) {
      state.materials = action.payload;
    },
    setEstimate(state, action: PayloadAction<Estimate>) {
      state.estimate = action.payload;
    },
    setEstimateAddOns(state, action: PayloadAction<EstimateAddOns[]>) {
      state.estimateAddOns = action.payload;
    },
    setAdminUsers(state, action: PayloadAction<AdminUserModel[]>) {
      state.adminUsers = action.payload;
    },
  },
});
export const {
  userLogin,
  setLeads,
  setProjects,
  setInsuranceCompanies,
  setProjectDetails,
  setMaterials,
  setEstimate,
  setEstimateAddOns,
  userLogout,
  setAdminUsers,
} = userSlice.actions;
export default userSlice.reducer;
