import axios from "axios";
import { PUBLIC_BASE_URL } from "./appConst";

export const LoginAPI = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${PUBLIC_BASE_URL}/sales/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return {
      msg: "error occurred",
    };
  }
};
