import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { GetUsers } from "../../../redux/api/users.api";
import { useAppSelector } from "../../../redux/hooks";
import { AdminUserModel } from "../../../redux/types";
import { CreateUser } from "./CreateUser";
import { UserTable } from "./UserTable";
import { setAdminUsers } from "../../../redux/reducers";

interface DashboardProps {}

export const Users: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();

  const { adminUsers } = useAppSelector((state) => state.userReducer);

  useEffect(() => {
    onFetchUsers();
  }, []);

  const onFetchUsers = async () => {
    try {
      const response = await GetUsers();
      if (response.data) {
        dispatch(setAdminUsers(response.data as AdminUserModel[]));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [editUser, setEditUser] = useState<AdminUserModel>();

  const [addUser, setAdduser] = useState(false);

  const onAddUser = () => {
    setEditUser(undefined);
    setAdduser(!addUser);
  };

  const onTapEditUser = (editUserFromTable: AdminUserModel) => {
    setEditUser(editUserFromTable);
    setAdduser(true);
  };

  const resetPassword = (email: string) => {
    // dispatch();
    // onResetPassword({
    //   email,
    // })
    setTimeout(() => {
      toast("New password is sent your registred Email Id!", {
        type: "success",
      });
    }, 1000);
  };

  // TODO: handle restaurant users
  if (addUser) {
    return (
      <CreateUser
        editUser={editUser}
        onTapAdd={onAddUser}
        onFetchUsers={() => {
          onAddUser();
          onFetchUsers();
        }}
      />
    );
  } else {
    return (
      <UserTable
        onTapResetPassword={resetPassword}
        onTapEditUser={onTapEditUser}
        users={adminUsers}
        onTapAddUser={onAddUser}
      />
    );
  }
};
