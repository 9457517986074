import React, { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {
  AddressInput,
  CreateLeadInput,
  LeadInput,
  LeadResponse,
} from "../../../redux/types";
import { CreateSalesLead } from "../../../redux/api";
import { toast } from "react-toastify";

const MAP_API_KEY = "AIzaSyCrehcsgPAr4WyJkkEpq3BPOtPMGhOhT_s";
export default function CreateLead() {
  const [location, setLocation] = useState<CreateLeadInput | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerReferralName, setCustomerReferralName] = useState("");

  const [isScrollEnabled, setScrollEnabled] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [humanShown, setHumanShown] = useState(true);
  const [focused, setFocused] = useState(false);
  const [predictions, setPredictions] = useState(false);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [reset, setReset] = useState(false);
  const [start, setStart] = useState(false);
  const [placeholder, setPlaceholder] = useState("Search address");
  const [addressSearching, setAddressSearching] = useState("");
  const [selectedAddress, setSelectedAddress] =
    useState<CreateLeadInput | null>(null);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyBzepvXcfi4uJL7LpF-aeKW8FVUdDxTonA",
  });

  const onPlaceChanged = async (pickedPlace: any) => {
    const address = pickedPlace as CreateLeadInput;

    setLocation(address);
    setSelectedAddress(address);
  };

  function parseCityAndState(address: any) {
    var regex = /^([\w\s]+?),\s*([\w\s]+?),\s*([\w\s]+)$/;

    var matches = address.match(regex);
    if (matches) {
      var city = matches[1].trim();
      var state = matches[2].trim();
      return {
        city: city,
        state: state,
      };
    } else {
      return null; // Address format doesn't match expected pattern
    }
  }

  const onTapGetStarted = async () => {
    if (!location) {
      console.log("hello");
      return;
    }
    const cityAndState = parseCityAndState(
      location.structured_formatting.secondary_text
    );
    const lead: LeadInput = {
      street_address: location.structured_formatting.main_text,
      unit: "",
      city: cityAndState?.city,
      zip_code: "10257", // need to check from map api
      state: cityAndState?.state,
      phone: phoneNumber,
      email: email,
      first_name: firstName,
      last_name: lastName,
      customer_referral_name: customerReferralName,
      utm: "sales",
      place_id: location.place_id,
    };

    try {
      const response = await CreateSalesLead(lead);
      console.log(response);
      if (response.message === "Lead Created") {
        toast("Lead successfully created.", {
          type: "success",
        });
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setCustomerReferralName("");
        setLocation(null);
        setSelectedAddress(null);
      }
      if (response.msg === "error occured") {
        toast("Error creating lead.", {
          type: "error",
        });
      }
    } catch (error) {
      toast("Error creating lead.", {
        type: "error",
      });
    }
  };

  return (
    <div
      style={{
        justifyContent: "center",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <div className="bg-white p-5 rounded-xl">
        <h2 className="text-3xl mt-[20%] sm:mt-[10%] ml-2">Submit a lead</h2>
        <form className="flex flex-col">
          <input
            onChange={(e) => setFirstName(e.target.value)}
            style={{ outline: "none" }}
            value={firstName}
            placeholder="First name"
            className="bg-[#f3f3f3] p-2 rounded w-[100%] sm:w-[300px] m-2 text-black  focus:border focus:border-1 focus:border-[#65D191]"
          />
          <input
            onChange={(e) => setLastName(e.target.value)}
            style={{ outline: "none" }}
            value={lastName}
            placeholder="Last name"
            className="bg-[#f3f3f3] p-2 rounded w-[100%] sm:w-[300px] m-2 text-black  focus:border focus:border-1 focus:border-[#65D191]"
          />
          <input
            onChange={(e) => setEmail(e.target.value)}
            style={{ outline: "none" }}
            value={email}
            placeholder="Email"
            className="bg-[#f3f3f3] p-2 rounded w-[100%] sm:w-[300px] m-2 text-black  focus:border focus:border-1 focus:border-[#65D191]"
          />
          <input
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ outline: "none" }}
            value={phoneNumber}
            placeholder="Phone number"
            className="bg-[#f3f3f3] p-2 rounded w-[100%] sm:w-[300px] m-2 text-black focus:border focus:border-1 focus:border-[#65D191]"
          />
          <input
            onChange={(e) => setCustomerReferralName(e.target.value)}
            style={{ outline: "none" }}
            value={customerReferralName}
            placeholder="Customer referral name"
            className="bg-[#f3f3f3] p-2 rounded w-[100%] sm:w-[300px] m-2 text-black focus:border focus:border-1 focus:border-[#65D191]"
          />
          <input
            placeholder="Search address"
            onFocus={() => setFocused(true)}
            value={location?.description || addressSearching}
            style={{ outline: "none", color: "black" }}
            onChange={(evt) => {
              evt.stopPropagation();
              setPredictions(true);
              setAddressSearching(evt.target.value);
              getPlacePredictions({ input: evt.target.value });
            }}
            className="bg-[#f3f3f3] p-2 rounded w-[100%] sm:w-[300px] m-2 text-black  focus:border focus:border-1 focus:border-[#65D191]"
          />
          {selectedAddress && (
            <div
              style={{
                color: "#65D191",
                paddingLeft: 20,
                marginTop: 5,
                marginBottom: 5,
                borderRadius: 15,
                display: "flex",
                alignItems: "center",
                maxWidth: "300px",
                overflow: "hidden",
                whiteSpace: "normal",
              }}
            >
              <span>
                <button
                  style={{
                    fontSize: 12,
                    marginRight: 10,
                    height: 25,
                    width: 25,
                  }}
                  onClick={() => setSelectedAddress(null)}
                >
                  <span>
                    <img
                      src="https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/e6ac4adc-34c5-4a84-f7c5-06d86076ad00/chatImage"
                      style={{ height: 25, width: 25 }}
                    />
                  </span>
                </button>
              </span>
              {selectedAddress.description}
            </div>
          )}
        </form>
        <div>
          {predictions &&
            focused &&
            placePredictions.map((item, index) => (
              <div
                className=""
                key={index}
                style={{
                  color: "black",
                  paddingLeft: 20,
                  marginTop: 10,
                  marginBottom: 10,
                  borderRadius: 15,
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "300px",
                  overflow: "hidden",
                  whiteSpace: "normal",
                }}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onPlaceChanged(item);
                  setPredictions(false);
                  setFocused(false);
                }}
              >
                {item.description}
              </div>
            ))}

          <button
            className="bg-black text-white p-2 rounded w-[100%] sm:w-[300px] m-2 hover:text-[#65D191]"
            onClick={onTapGetStarted}
          >
            Submit lead
          </button>
        </div>
      </div>
    </div>
  );
}
