import { MenuItem } from "../types/interface";
import projectIcon from "../images/projectIcon.png";
import userIcon from "../images/userIcon.png";
import leadIcon from "../images/leadIcon.png";
import waitListsIcon from "../images/waitlistsIcon.png";
import logoutIcon from "../images/logoutIcon.png";
import settingIcon from "../images/settingsIcon.png";

import projectIconG from "../images/projectIcon_G.png";
import userIconG from "../images/userIcon_G.png";
import leadIconG from "../images/leadIcon_G.png";
import waitListsIconG from "../images/waitlistsIcon_G.png";
import logoutIconG from "../images/logoutIcon_G.png";
import settingIconG from "../images/settingsIcon_G.png";

export const MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    text: "Leads",
    route: "/leads",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/8d6efa1c-c100-4507-8359-d25d94715300/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/63f25a4a-87a9-43ba-a8f3-efd70e63ee00/chatImage",
  },

  {
    id: 2,
    text: "Projects",
    route: "/projects",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/ed71e034-56c1-43ed-a8cd-ae2b8dd4d600/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/3e826582-9118-4752-ea2c-51ef55578d00/chatImage",
  },
  {
    id: 3,
    text: "Submission",
    route: "/create-lead",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/0a5ade38-4d3d-4d17-5adf-9e85760b7000/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/fb6f56f4-cbb3-4256-5edd-9bf94969ff00/chatImage",
  },

  {
    id: 4,
    text: "Logout",
    route: "/logout",
    icon: "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/904d01dc-bf5e-4fc9-50f5-fb0afa96cf00/chatImage",
    icon_G:
      "https://imagedelivery.net/ljlAKBP_91m1yO7rrhjKgQ/2021d800-30b0-41bb-34ee-808eac3fc600/chatImage",
  },
];
