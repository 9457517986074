/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  TapButton,
  AppCSS,
  Spacer,
  TxtInput,
  CloseButton,
  ButtonWithIcon,
} from "../../../components";
import { Lbl } from "../../../components/labels";
import { ColDiv, RowDiv } from "../../../components/misc/misc.styled";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { CLIENT_URL } from "../../../utils";
import { AppAlert } from "../../../components/dialogue/Dialogue";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/BorderColor";
import { Checkbox, FormControlLabel } from "@mui/material";
import { toast } from "react-toastify";
import { LocationBadge } from "../../../components/misc/LocationBagde";
import { useAppSelector } from "../../../redux/hooks";
import { LeadsTable } from "./LeadTable";
import { GetLeads } from "../../../redux/api";
import { setLeads } from "../../../redux/reducers";
import { LeadModel } from "../../../redux/types";
import { Container } from "../../../globalstyled";
interface DashboardProps {}

export const Leads: React.FC<DashboardProps> = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userReducer = useAppSelector((state) => state.userReducer);
  const { leads } = userReducer;

  useEffect(() => {
    onFetchLeads();
  }, []);

  const onFetchLeads = async () => {
    try {
      const data = await GetLeads();

      const userData = data as {
        message: string;
        data: { count: number; leads: LeadModel[] };
      };
      if (userData.data) {
        const { leads } = userData.data;
        dispatch(setLeads(leads));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hostContainer = () => {
    return (
      <Container>
        <AppAlert
          open={false}
          title={`Reset Password!`}
          message={"Are you sure to Reset Your Password?"}
          onClose={() => {}}
          onConfirm={() => {}}
        />
        <LeadsTable leads={leads} />
      </Container>
    );
  };

  return hostContainer();
};
